:root {

  --color-primary-0: #8F77DB;
  --color-primary-1: #D9CFF9;
  --color-primary-2: #B2A0EC;
  --color-primary-3: #7055C5;
  --color-primary-4: #5439AC;

  --color-secondary-1-0: #FFFF7C;
  --color-secondary-1-1: #FFFFCF;
  --color-secondary-1-2: #FFFFA2;
  --color-secondary-1-3: #FFFF5B;
  --color-secondary-1-4: #FAFA3D;

  --color-secondary-2-0: #FFD37C;
  --color-secondary-2-1: #FFEFCF;
  --color-secondary-2-2: #FFE0A2;
  --color-secondary-2-3: #FFC85B;
  --color-secondary-2-4: #FABB3D;

  --font-color-100: #8f77db;
  --font-color: #e1d8ff;
  --foreground-rgb: 0, 0, 0;
  
  --card-height: 15vh;
  --card-width: 30vw;

  --swiper-theme-color: #5439AC !important;
  --swiper-navigation-color: #5439AC;

  --cupertino-pane-background: #ffe0a279 !important;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: rgb(var(--foreground-rgb));
}

a {
  color: inherit;
  text-decoration: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

.parallaxImage {
  width: 100vw !important;
  height: 100vh !important;
}

.parallaxImageGf {
  width: 100vw !important;
  height: 250vh !important;
}

.glass {
  width: 90%;
  height: 70%;
  display: flex;
  flex-direction: column;
  background: rgba(161, 140, 94, 0.37);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.5px);
  -webkit-backdrop-filter: blur(6.5px);
  border: 1px solid rgba(161, 140, 94, 0.3);
  border-radius: 15px;
  padding: 1vh;
}

.glassPane {
  background: rgba(161, 140, 94, 0.37);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.5px);
  -webkit-backdrop-filter: blur(6.5px);
}

.swiper {
  width: 100% !important;
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.swiper-slide {
  background-position: center !important;
  background-size: cover !important;
  width: 400px !important;
  height: 400px !important;
}

.swiper-slide img {
  display: block !important;
  width: 100% !important;
  height: 100% !important;
}

.whenSwiper {
  width: 200px !important;
  height: 250px;
  position: absolute !important;
  left: 50%;
  top: 50%;
  margin-left: -100px !important;
  margin-top: -150px;
}

.whenSwiper div.swiper-button-prev {
  margin-left: -50px !important;
}

.whenSwiper .swiper-button-next {
  margin-right: -50px !important;
}

.whenSwiperSlide {
  width: 200px !important;
  height: 150px !important;
}

.whenSwiperSlide img {
  display: block !important;
  width: 100% !important;
  height: 100% !important;
}

@media(min-width: 500px) and (max-width: 767px) {
  .whenSwiper {
    width: 300px !important;
    height: 350px !important;
    margin-left: -150px !important;
  }

  .whenSwiperSlide {
    width: 300px !important;
    height: 250px !important;
  }
}

@media(min-width: 768px) and (max-width: 1200px) {
  .whenSwiper {
    width: 300px !important;
    margin-left: -150px !important;
  }

  .whenSwiperSlide {
    width: 300px !important;
  }

  .parallaxImageGf {
    height: 140vh !important;
  }
}

@media (min-width: 1200px) {
  .glass {
    height: 90%;
  }
  .whenSwiper {
    width: 400px !important;
    height: 450px !important;
    margin-left: -200px !important;
    margin-top: -200px;
  }

  .whenSwiperSlide {
    width: 400px !important;
    height: 350px !important;
  }

  .parallaxImageGf {
    height: 140vh !important;
  }
}

@media (min-height: 780px) {
  .whenSwiperSlide {
    height: 350px;
  }
}

/* roboto-cyrillic-ext-100-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: url(/_next/static/media/roboto-cyrillic-ext-100-normal.3078471e.woff2) format('woff2'), url(/_next/static/media/roboto-all-100-normal.41a6aca9.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}
/* roboto-cyrillic-100-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: url(/_next/static/media/roboto-cyrillic-100-normal.08925358.woff2) format('woff2'), url(/_next/static/media/roboto-all-100-normal.41a6aca9.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}
/* roboto-greek-ext-100-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: url(/_next/static/media/roboto-greek-ext-100-normal.45380222.woff2) format('woff2'), url(/_next/static/media/roboto-all-100-normal.41a6aca9.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}
/* roboto-greek-100-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: url(/_next/static/media/roboto-greek-100-normal.96ca3110.woff2) format('woff2'), url(/_next/static/media/roboto-all-100-normal.41a6aca9.woff) format('woff');
  unicode-range: U+0370-03FF;
}
/* roboto-vietnamese-100-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: url(/_next/static/media/roboto-vietnamese-100-normal.a8b7b875.woff2) format('woff2'), url(/_next/static/media/roboto-all-100-normal.41a6aca9.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}
/* roboto-latin-ext-100-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: url(/_next/static/media/roboto-latin-ext-100-normal.9780f35c.woff2) format('woff2'), url(/_next/static/media/roboto-all-100-normal.41a6aca9.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}
/* roboto-latin-100-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: url(/_next/static/media/roboto-latin-100-normal.3fa690b6.woff2) format('woff2'), url(/_next/static/media/roboto-all-100-normal.41a6aca9.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* roboto-cyrillic-ext-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/_next/static/media/roboto-cyrillic-ext-300-normal.cd7c5715.woff2) format('woff2'), url(/_next/static/media/roboto-all-300-normal.39add8fb.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}
/* roboto-cyrillic-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/_next/static/media/roboto-cyrillic-300-normal.88798412.woff2) format('woff2'), url(/_next/static/media/roboto-all-300-normal.39add8fb.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}
/* roboto-greek-ext-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/_next/static/media/roboto-greek-ext-300-normal.bc5ce703.woff2) format('woff2'), url(/_next/static/media/roboto-all-300-normal.39add8fb.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}
/* roboto-greek-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/_next/static/media/roboto-greek-300-normal.25dc89b0.woff2) format('woff2'), url(/_next/static/media/roboto-all-300-normal.39add8fb.woff) format('woff');
  unicode-range: U+0370-03FF;
}
/* roboto-vietnamese-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/_next/static/media/roboto-vietnamese-300-normal.b3d3e960.woff2) format('woff2'), url(/_next/static/media/roboto-all-300-normal.39add8fb.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}
/* roboto-latin-ext-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/_next/static/media/roboto-latin-ext-300-normal.37d4965d.woff2) format('woff2'), url(/_next/static/media/roboto-all-300-normal.39add8fb.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}
/* roboto-latin-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/_next/static/media/roboto-latin-300-normal.a4eae32d.woff2) format('woff2'), url(/_next/static/media/roboto-all-300-normal.39add8fb.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* roboto-cyrillic-ext-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/roboto-cyrillic-ext-400-normal.d7827ae3.woff2) format('woff2'), url(/_next/static/media/roboto-all-400-normal.2e9e9400.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}
/* roboto-cyrillic-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/roboto-cyrillic-400-normal.2d9c9d60.woff2) format('woff2'), url(/_next/static/media/roboto-all-400-normal.2e9e9400.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}
/* roboto-greek-ext-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/roboto-greek-ext-400-normal.2b547ded.woff2) format('woff2'), url(/_next/static/media/roboto-all-400-normal.2e9e9400.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}
/* roboto-greek-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/roboto-greek-400-normal.63e6dc18.woff2) format('woff2'), url(/_next/static/media/roboto-all-400-normal.2e9e9400.woff) format('woff');
  unicode-range: U+0370-03FF;
}
/* roboto-vietnamese-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/roboto-vietnamese-400-normal.c95fc061.woff2) format('woff2'), url(/_next/static/media/roboto-all-400-normal.2e9e9400.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}
/* roboto-latin-ext-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/roboto-latin-ext-400-normal.21abc8c8.woff2) format('woff2'), url(/_next/static/media/roboto-all-400-normal.2e9e9400.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}
/* roboto-latin-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/roboto-latin-400-normal.f2894edc.woff2) format('woff2'), url(/_next/static/media/roboto-all-400-normal.2e9e9400.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* roboto-cyrillic-ext-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/_next/static/media/roboto-cyrillic-ext-500-normal.a1b5c90d.woff2) format('woff2'), url(/_next/static/media/roboto-all-500-normal.d96daa81.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}
/* roboto-cyrillic-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/_next/static/media/roboto-cyrillic-500-normal.aa68ea54.woff2) format('woff2'), url(/_next/static/media/roboto-all-500-normal.d96daa81.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}
/* roboto-greek-ext-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/_next/static/media/roboto-greek-ext-500-normal.7ea6cffa.woff2) format('woff2'), url(/_next/static/media/roboto-all-500-normal.d96daa81.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}
/* roboto-greek-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/_next/static/media/roboto-greek-500-normal.533b03d2.woff2) format('woff2'), url(/_next/static/media/roboto-all-500-normal.d96daa81.woff) format('woff');
  unicode-range: U+0370-03FF;
}
/* roboto-vietnamese-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/_next/static/media/roboto-vietnamese-500-normal.7f8c0554.woff2) format('woff2'), url(/_next/static/media/roboto-all-500-normal.d96daa81.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}
/* roboto-latin-ext-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/_next/static/media/roboto-latin-ext-500-normal.85ebfb55.woff2) format('woff2'), url(/_next/static/media/roboto-all-500-normal.d96daa81.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}
/* roboto-latin-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/_next/static/media/roboto-latin-500-normal.3170fd9a.woff2) format('woff2'), url(/_next/static/media/roboto-all-500-normal.d96daa81.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* roboto-cyrillic-ext-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/roboto-cyrillic-ext-700-normal.dd3651fb.woff2) format('woff2'), url(/_next/static/media/roboto-all-700-normal.ca3d0fdb.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}
/* roboto-cyrillic-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/roboto-cyrillic-700-normal.258a358e.woff2) format('woff2'), url(/_next/static/media/roboto-all-700-normal.ca3d0fdb.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}
/* roboto-greek-ext-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/roboto-greek-ext-700-normal.a8d16efd.woff2) format('woff2'), url(/_next/static/media/roboto-all-700-normal.ca3d0fdb.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}
/* roboto-greek-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/roboto-greek-700-normal.432b858b.woff2) format('woff2'), url(/_next/static/media/roboto-all-700-normal.ca3d0fdb.woff) format('woff');
  unicode-range: U+0370-03FF;
}
/* roboto-vietnamese-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/roboto-vietnamese-700-normal.72bf832f.woff2) format('woff2'), url(/_next/static/media/roboto-all-700-normal.ca3d0fdb.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}
/* roboto-latin-ext-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/roboto-latin-ext-700-normal.6af98c24.woff2) format('woff2'), url(/_next/static/media/roboto-all-700-normal.ca3d0fdb.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}
/* roboto-latin-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/roboto-latin-700-normal.71b2beb8.woff2) format('woff2'), url(/_next/static/media/roboto-all-700-normal.ca3d0fdb.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* roboto-cyrillic-ext-900-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url(/_next/static/media/roboto-cyrillic-ext-900-normal.86c8a736.woff2) format('woff2'), url(/_next/static/media/roboto-all-900-normal.c54d2563.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}
/* roboto-cyrillic-900-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url(/_next/static/media/roboto-cyrillic-900-normal.321ff2e7.woff2) format('woff2'), url(/_next/static/media/roboto-all-900-normal.c54d2563.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}
/* roboto-greek-ext-900-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url(/_next/static/media/roboto-greek-ext-900-normal.6f02620e.woff2) format('woff2'), url(/_next/static/media/roboto-all-900-normal.c54d2563.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}
/* roboto-greek-900-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url(/_next/static/media/roboto-greek-900-normal.9f4d93bc.woff2) format('woff2'), url(/_next/static/media/roboto-all-900-normal.c54d2563.woff) format('woff');
  unicode-range: U+0370-03FF;
}
/* roboto-vietnamese-900-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url(/_next/static/media/roboto-vietnamese-900-normal.def30111.woff2) format('woff2'), url(/_next/static/media/roboto-all-900-normal.c54d2563.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}
/* roboto-latin-ext-900-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url(/_next/static/media/roboto-latin-ext-900-normal.60a74847.woff2) format('woff2'), url(/_next/static/media/roboto-all-900-normal.c54d2563.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}
/* roboto-latin-900-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url(/_next/static/media/roboto-latin-900-normal.2f95c19e.woff2) format('woff2'), url(/_next/static/media/roboto-all-900-normal.c54d2563.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* vietnamese */
@font-face {
  font-family: '__Ephesis_a1d899';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/ee7478958c40300f-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Ephesis_a1d899';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/b196d69f754279a9-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Ephesis_a1d899';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/af99abb8a8d986de-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Ephesis_Fallback_a1d899';src: local("Arial");ascent-override: 139.70%;descent-override: 62.09%;line-gap-override: 0.00%;size-adjust: 64.43%
}.__className_a1d899 {font-family: '__Ephesis_a1d899', '__Ephesis_Fallback_a1d899';font-weight: 400;font-style: normal
}

/* cyrillic */
@font-face {
  font-family: '__Poiret_One_92400e';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/edbe1f30817b1c85-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: '__Poiret_One_92400e';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/2200dd95e1ba228a-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poiret_One_92400e';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/036afb91967e20ed-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Poiret_One_Fallback_92400e';src: local("Arial");ascent-override: 100.16%;descent-override: 21.66%;line-gap-override: 0.00%;size-adjust: 96.04%
}.__className_92400e {font-family: '__Poiret_One_92400e', '__Poiret_One_Fallback_92400e';font-weight: 400;font-style: normal
}


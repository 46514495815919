:root {

  --color-primary-0: #8F77DB;
  --color-primary-1: #D9CFF9;
  --color-primary-2: #B2A0EC;
  --color-primary-3: #7055C5;
  --color-primary-4: #5439AC;

  --color-secondary-1-0: #FFFF7C;
  --color-secondary-1-1: #FFFFCF;
  --color-secondary-1-2: #FFFFA2;
  --color-secondary-1-3: #FFFF5B;
  --color-secondary-1-4: #FAFA3D;

  --color-secondary-2-0: #FFD37C;
  --color-secondary-2-1: #FFEFCF;
  --color-secondary-2-2: #FFE0A2;
  --color-secondary-2-3: #FFC85B;
  --color-secondary-2-4: #FABB3D;

  --font-color-100: #8f77db;
  --font-color: #e1d8ff;
  --foreground-rgb: 0, 0, 0;
  
  --card-height: 15vh;
  --card-width: 30vw;

  --swiper-theme-color: #5439AC !important;
  --swiper-navigation-color: #5439AC;

  --cupertino-pane-background: #ffe0a279 !important;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: rgb(var(--foreground-rgb));
}

a {
  color: inherit;
  text-decoration: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

.parallaxImage {
  width: 100vw !important;
  height: 100vh !important;
}

.parallaxImageGf {
  width: 100vw !important;
  height: 250vh !important;
}

.glass {
  width: 90%;
  height: 70%;
  display: flex;
  flex-direction: column;
  background: rgba(161, 140, 94, 0.37);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.5px);
  -webkit-backdrop-filter: blur(6.5px);
  border: 1px solid rgba(161, 140, 94, 0.3);
  border-radius: 15px;
  padding: 1vh;
}

.glassPane {
  background: rgba(161, 140, 94, 0.37);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.5px);
  -webkit-backdrop-filter: blur(6.5px);
}

.swiper {
  width: 100% !important;
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.swiper-slide {
  background-position: center !important;
  background-size: cover !important;
  width: 400px !important;
  height: 400px !important;
}

.swiper-slide img {
  display: block !important;
  width: 100% !important;
  height: 100% !important;
}

.whenSwiper {
  width: 200px !important;
  height: 250px;
  position: absolute !important;
  left: 50%;
  top: 50%;
  margin-left: -100px !important;
  margin-top: -150px;
}

.whenSwiper div.swiper-button-prev {
  margin-left: -50px !important;
}

.whenSwiper .swiper-button-next {
  margin-right: -50px !important;
}

.whenSwiperSlide {
  width: 200px !important;
  height: 150px !important;
}

.whenSwiperSlide img {
  display: block !important;
  width: 100% !important;
  height: 100% !important;
}

@media(min-width: 500px) and (max-width: 767px) {
  .whenSwiper {
    width: 300px !important;
    height: 350px !important;
    margin-left: -150px !important;
  }

  .whenSwiperSlide {
    width: 300px !important;
    height: 250px !important;
  }
}

@media(min-width: 768px) and (max-width: 1200px) {
  .whenSwiper {
    width: 300px !important;
    margin-left: -150px !important;
  }

  .whenSwiperSlide {
    width: 300px !important;
  }

  .parallaxImageGf {
    height: 140vh !important;
  }
}

@media (min-width: 1200px) {
  .glass {
    height: 90%;
  }
  .whenSwiper {
    width: 400px !important;
    height: 450px !important;
    margin-left: -200px !important;
    margin-top: -200px;
  }

  .whenSwiperSlide {
    width: 400px !important;
    height: 350px !important;
  }

  .parallaxImageGf {
    height: 140vh !important;
  }
}

@media (min-height: 780px) {
  .whenSwiperSlide {
    height: 350px;
  }
}
